<template>
  <div class="">
    <div class="d-flex justify-content-center align-items-center">
      <div class="w-100" style="padding: 1.5rem 0.5rem">
        <div class="user-detail">
          <h1>Select your desired workspace!</h1>
        </div>
        <FilterBox
          v-model.trim="filterText"
          placeholder="Search..."
        ></FilterBox>
        <div
          :class="invitedLogin ? 'workspace-block-2' : 'workspace-block-1'"
          class="my-2"
          v-if="getWorkSpaces.length > 0"
        >
          <div
            class="d-flex align-items-center workspace-row my-2"
            @click="skipWorkspace"
          >
            <div class="user-profile-space p-3" :style="randomColors[8]">
              {{ extractInitials(`Main Account`) }}
            </div>
            <div class="mx-2">
              <div class="font-weight-bold">Main Account</div>
            </div>
          </div>
          <div
            class="d-flex align-items-center workspace-row my-2"
            v-for="(space, index) in getWorkSpaces"
            :key="index"
            @click="switchWorkspace(space)"
          >
            <div class="user-profile-space p-3" :style="randomColors[index]">
              {{ extractInitials(space.name) }}
            </div>
            <div class="mx-2">
              <div class="font-weight-bold">{{ space.name }}</div>
              <div class="" style="color: #616161">{{ space.email }}</div>
            </div>
          </div>
        </div>
        <div
          :class="invitedLogin ? 'workspace-block-2' : 'workspace-block-1'"
          class="my-2 d-flex align-items-center justify-content-center"
          v-else
        >
          <p class="text-center" style="font-weight: 500">
            No Workspace found!
          </p>
        </div>
        <!-- <div class="" style="text-align:right">
                          <div class="skip-btn" @click="skipWorkspace">
                              Login to Main Account <i class="fa-solid arrow fa-arrow-right ml-2"></i>
                          </div>
                      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import FilterBox from "../Dashboard/Layout/FilterBox.vue";
export default {
  components: { FilterBox },
  props: ["workSpaces"],
  data() {
    return {
      filterText: "",
      randomColors: [],
    };
  },
  computed: {
    invitedLogin() {
      return this.$route.query.invitedLogin || false;
    },
    getWorkSpaces() {
      return this.workSpaces.filter(
        (space) =>
          space.name.toLowerCase().includes(this.filterText.toLowerCase()) ||
          space.email.toLowerCase().includes(this.filterText.toLowerCase())
      );
    },
  },
  methods: {
    extractInitials(name) {
      const nameParts = name.split(" ");

      if (nameParts.length === 1) {
        return name.substring(0, 2);
      } else if (nameParts.length >= 2) {
        const [firstName, lastName] = nameParts;
        const firstInitial = firstName.substring(0, 1);
        const lastInitial = lastName.substring(0, 1);
        return `${firstInitial}${lastInitial}`;
      }

      return ""; // Return an empty string if the input format doesn't match expectations
    },
    generateColorArray() {
      const colors = [];

      for (let i = 0; i < 10; i++) {
        const randomColor =
          "#" + Math.floor(Math.random() * 16777215).toString(16);

        // Function to adjust color brightness for background and font
        const adjustBrightness = (color, amount) => {
          const parseColor = parseInt(color.slice(1), 16);
          const r = (parseColor >> 16) + amount;
          const g = ((parseColor >> 8) & 0xff) + amount;
          const b = (parseColor & 0xff) + amount;
          const clamp = (num) => Math.min(255, Math.max(0, num));
          const clampRgb = `#${((clamp(r) << 16) | (clamp(g) << 8) | clamp(b))
            .toString(16)
            .padStart(6, "0")}`;
          return clampRgb;
        };

        const backgroundColor = adjustBrightness(randomColor, 30); // Slightly lighter background
        const fontColor = adjustBrightness(randomColor, -30); // Slightly darker font color

        colors.push({
          background: backgroundColor,
          color: fontColor,
        });
      }
      this.randomColors = colors;
    },
    skipWorkspace() {
      this.$router.push("/");
    },
    async switchWorkspace({ id }) {
      this.$toasted.show("Switching to your workspace. Please wait a moment!", {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 4000,
      });
      let payload = {
        workspace_id: id,
      };

      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "post",
        resource: "rolesPermissions",
        method: "switchWorkspace",
        params: payload,
      });

      let { access_token } = response;
      if (access_token) {
        localStorage.setItem("user_access_token", access_token);

        localStorage.setItem("isWorkSpaceUser", true);

        this.$router.push(`/`);
      }
    },
  },
  mounted() {
    this.generateColorArray();
  },
};
</script>

<style scoped>
.skip-btn {
  color: #4d1b7e;
  display: inline-flex;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.user-profile {
  border-radius: 12px;
  background: #a7ebde;
  color: #245246;
  font-weight: 500;
}
.user-profile-space {
  text-transform: uppercase;
  /* border: 1px solid grey; */
  border-radius: 12px;

  font-weight: 500;
}
.user-detail h1 {
  color: #161e34;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 10px;
}

.workspace-block-1 {
  height: 300px;
  overflow-x: auto;
}
.workspace-block-2 {
  max-height: 300px;
  overflow-x: auto;
}
.workspace-row {
  cursor: pointer;
  transition: 0.3s ease all;
}
.workspace-row:hover {
  background: rgba(204, 204, 204, 0.5);
  border-radius: 12px;
}
.skip-btn .arrow {
  transition: transform 0.3s ease;
}

/* Hover state */
.skip-btn:hover .arrow {
  transform: translateX(5px);
}
</style>
